
/* --- begin var customization ---- */

$font-size-base: 0.75rem;

/* --- end customization ------ */

@import "~bootstrap/scss/bootstrap";

/* --- begin extended bootstrap classes ---- */




/* --- custom global styles --- */

